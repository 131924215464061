import React from 'react';
import { Grid } from 'components/Grid';
import { ProjectMainLayout } from 'layouts/ProjectMainLayout';
import { PROJECTS } from 'constants/projects';
import { graphql, useStaticQuery } from 'gatsby';

export const ZiemowitView = () => {
    const images = useStaticQuery(graphql`
        query ZiemowitImages {
            main: file(name: { eq: "ziemowit-main" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
            lower: file(name: { eq: "ziemowit-lower" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
            upper: file(name: { eq: "ziemowit-upper" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
        }
    `);

    return (
        <ProjectMainLayout projectName={PROJECTS.ziemowit.name}>
            <Grid {...images} projectName={PROJECTS.ziemowit.name} />
        </ProjectMainLayout>
    );
};
